import React, { useState } from 'react'; // Removed 'useEffect' and 'Chart' imports
import './App.css';

function DataProcessing() {
  const [selectedMonthYear, setSelectedMonthYear] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState('Date');
  const [sortOrder, setSortOrder] = useState('asc');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedMonthYear) {
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        `https://uy1x84vnw5.execute-api.us-east-2.amazonaws.com/ExpenseTrackerFetch?userInput=${selectedMonthYear}`
      );

      if (response.ok) {
        const result = await response.json();
        setData(result);
      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (columnName) => {
    if (sortColumn === columnName) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(columnName);
      setSortOrder('asc');
    }
  };

  const sortedData = [...data].sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (sortColumn === 'Amount') {
      const aNumber = parseFloat(aValue);
      const bNumber = parseFloat(bValue);

      if (sortOrder === 'asc') {
        return aNumber - bNumber;
      } else {
        return bNumber - aNumber;
      }
    } else {
      if (sortOrder === 'asc') {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    }
  });

  const totals = {
    'Lauren - Necessary': 0,
    'Lauren - Unnecessary': 0,
    'Patrick - Necessary': 0,
    'Patrick - Unnecessary': 0,
    'Other - Necessary': 0,
    'Other - Unnecessary': 0,
    'Kids - Necessary': 0,
    'Kids - Unnecessary': 0,
    'Total - Necessary': 0,
    'Total - Unnecessary': 0,
    'Total - All Expenses': 0,
  };

  sortedData.forEach((item) => {
    const key = `${item.User} - ${item.Category}`;
    const amount = parseFloat(item.Amount);

    if (!isNaN(amount)) {
      totals[key] += amount;
      totals[`Total - ${item.Category}`] += amount;
      totals['Total - All Expenses'] += amount;
    }
  });

  return (
    <div>
      <h2>Data Processing</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Month/Year:</label>
          <input
            type="month"
            value={selectedMonthYear}
            onChange={(e) => setSelectedMonthYear(e.target.value)}
          />
        </div>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
  
      {loading ? (
        <p>Loading data...</p>
      ) : data.length === 0 ? (
        <p>No data available for the selected month/year.</p>
      ) : (
        <div>
          <div className="table-container">
          <table className="expense-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('User')}>User</th>
                <th onClick={() => handleSort('Date')}>Date</th>
                <th onClick={() => handleSort('Amount')}>Amount</th>
                <th onClick={() => handleSort('Category')}>Category</th>
                <th onClick={() => handleSort('SubCategory')}>Subcategory</th>
                <th onClick={() => handleSort('Notes')}>Notes</th>
              </tr>
            </thead>
            <tbody>
              {sortedData.map((item, index) => (
                <tr key={index}>
                  <td>{item.User}</td>
                  <td>{item.Date}</td>
                  <td>{item.Amount}</td>
                  <td>{item.Category}</td>
                  <td>{item.SubCategory}</td>
                  <td>{item.Notes}</td>
                </tr>
              ))}
            </tbody>
          </table>
  
          <table className="expense-table">
            <thead>
              <tr>
                <th>Category</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(totals).map(([category, total]) => (
                <tr key={category}>
                  <td>{category}</td>
                  <td>{total.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </div>
      )}
    </div>
  );
}

export default DataProcessing;
