import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ExpenseForm from './expenseform'; // Update the import path
import DataProcessing from './dataprocessing'; // Update the import path

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          {/* Your header content here */}
        </header>
        
        {/* Define routes for your components */}
        <Routes>
          <Route path="/" element={<ExpenseForm />} /> {/* Default route */}
          <Route path="/expenseform" element={<ExpenseForm />} />
          <Route path="/dataprocessing" element={<DataProcessing />} />
        </Routes>
        
        <nav>
          <ul>
            <li>
              <Link to="/expenseform">Expense Form</Link>
            </li>
            <li>
              <Link to="/dataprocessing">Data Processing</Link>
            </li>
          </ul>
        </nav>
      </div>
    </Router>
  );
}

export default App;
