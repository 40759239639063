import React, { useState } from 'react';
import './App.css';
import './logo.css';
import { Link } from 'react-router-dom';
function ExpenseForm() {
  const [user, setUser] = useState('Lauren');
  const [category, setCategory] = useState('Necessary');
  const [subcategory, setSubcategory] = useState('Bill');
  const [date, setDate] = useState('');
  const [amount, setAmount] = useState('');
  const [notes, setNotes] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a JavaScript object with the form data
    const formData = {
      user: user,
      category: category,
      subcategory: subcategory,
      date: date,
      amount: parseFloat(amount), // Convert amount to a float
      notes: notes,
    };

    console.log('Form Data:', formData);

    // Send a POST request to your AWS Lambda API
    try {
      const response = await fetch('https://2wpthsb9gc.execute-api.us-east-2.amazonaws.com/ExpenseTracker', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Convert the JavaScript object to a JSON string
      });

      if (response.ok) {
        console.log('Expense added successfully');
        // Clear form fields on success
        setUser('Lauren');
        setCategory('Necessary');
        setSubcategory('Bill');
        setDate('');
        setAmount('');
        setNotes('');
        setIsSubmitted(true);
      } else {
        console.error('Failed to add expense');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <img src="/splash2.png" alt="Logo" />
      <h2>Add Expense</h2>
      {isSubmitted ? (
      <div className="success-message">Expense added successfully!</div>
    ) : null}
      <form onSubmit={handleSubmit}>
        <div>
          <label>User:</label>
          <select value={user} onChange={(e) => setUser(e.target.value)}>
            <option value="Lauren">Lauren</option>
            <option value="Patrick">Patrick</option>
            <option value="Kids">Kids</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div>
          <label>Category:</label>
          <select value={category} onChange={(e) => setCategory(e.target.value)}>
            <option value="Necessary">Necessary</option>
            <option value="Unnecessary">Unnecessary</option>
          </select>
        </div>

        <div>
          <label>Subcategory:</label>
          <select
            value={subcategory}
            onChange={(e) => setSubcategory(e.target.value)}
          >
            {category === 'Necessary' ? (
              <>
                <option value="Bill">Bill</option>
                <option value="House Necessity">House Necessity</option>
                <option value="Medical">Medical</option>
                <option value="Gas">Gas</option>
              </>
            ) : (
              <>
                <option value="Eating Out">Eating Out</option>
                <option value="Physical Stuff">Physical Stuff</option>
                <option value="Other">Other</option>
              </>
            )}
          </select>
        </div>

        <div>
          <label>Date:</label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>

        <div>
          <label>Dollar Amount:</label>
          <input
            type="number"
            step="0.01" // Allows decimal values with two decimal places
            value={amount} // Bind the input value to a state variable (e.g., 'amount')
            onChange={(e) => setAmount(parseFloat(e.target.value))}
          />
        </div>

        <div>
          <label>Notes:</label> {/* Add the label for notes */}
          <textarea
            value={notes} // Bind the textarea value to the state variable 'notes'
            onChange={(e) => setNotes(e.target.value)} // Handle changes to notes
            rows="4" // You can adjust the number of rows as needed
          />
        </div>

        <div>
          
        <button type="submit" onClick={() => setIsSubmitted(false)}>Add Expense</button>
        </div>
      </form>
            {/* Simple link to Data Processing */}
            <div>
      </div>
    </div>
  );
}

export default ExpenseForm;
